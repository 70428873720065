/* You can add global styles to this file, and also import other style files */

@import 'aos/dist/aos.css';
html, body {
  height: 100%;
  scroll-behavior: smooth;
  background-color: black !important;
}
.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 99999 !important;
}

/*position: sticky;
    bottom: 0px;
    width: 100%;
    background: linear-gradient(350deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 25%, rgba(0, 0, 0, 0) 70%);
    height: 60vh;
    pointer-events: none;*/

.newThemeColor {
  background-image: linear-gradient(98.78deg, #056284 2.11%, #0C6180 2.12%, #5EB6D1 10.78%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*EireneSans*/

video::-internal-media-controls-overlay-cast-button {
  display: none;
}

@font-face {
  font-family: 'EireneSans';
  font-style: normal;
  line-height: normal;
  font-weight: 100;
  src: url(assets/fonts/EireneSans-Regular.otf);
}

@font-face {
  font-family: 'EireneSans';
  font-style: normal;
  line-height: normal;
  font-weight: 200;
  src: url(assets/fonts/EireneSans-Regular.otf);
}

@font-face {
  font-family: 'EireneSans';
  font-style: normal;
  line-height: normal;
  font-weight: 300;
  src: url(assets/fonts/EireneSans-Regular.otf);
}

@font-face {
  font-family: 'EireneSans';
  font-style: normal;
  line-height: normal;
  font-weight: 400;
  src: url(assets/fonts/EireneSans-Regular.otf);
}

@font-face {
  font-family: 'EireneSans';
  font-style: normal;
  line-height: normal;
  font-weight: 500;
  src: url(assets/fonts/EireneSans-Regular.otf);
}

@font-face {
  font-family: 'EireneSans';
  font-style: normal;
  line-height: normal;
  font-weight: 600;
  src: url(assets/fonts/EireneSans-Bold.otf);
}

body {
  margin: 0;
  font-family: 'EireneSans', 'Arial', sans-serif !important;
}

input, select, button {
  outline: none !important;
  box-shadow: none !important;
}

::selection {
  color: none;
  background: none;
}

/* For Mozilla Firefox */

::-moz-selection {
  color: none;
  background: none;
}

div:focus, img:focus, span:focus, input, select, button {
  outline: 0 !important;
}

.spacer10 {
  height: 10vh;
}

.spacer20 {
  height: 20vh;
}

.spacer40 {
  height: 40vh;
}

.spacer60 {
  height: 60vh;
}

.spacer80 {
  height: 80vh;
}

.creditasHeader {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
}

.cursorPointer {
  cursor: pointer !important;
}

.bodySpacing {
  padding: 0 100px !important;
}

.opacity_0 {
  opacity: 0;
}

.opacity_3 {
  opacity: 0.3;
}

.opacity_5 {
  opacity: 0.5;
}

.opacity_10 {
  opacity: 1;
}

.transition1 {
  transition: 0.1s linear;
}

.animateBlock {
  opacity: 0;
}

.animateBlock1 {
  opacity: 0.75;
}

.animateBlock2 {
  opacity: 0.5;
}

.animateBlock3 {
  opacity: 0.25;
}

.blink {
  animation: beat 0.25s infinite alternate;
  transform-origin: center;
}

.blink2 {
  animation: beat 0.35s infinite alternate;
  transform-origin: center;
}

@keyframes beat {
  to {
    transform: scale(1.4);
  }
}

@media (max-width: 992px) {
  .bodySpacing {
    padding: 0 22px !important;
  }
  .spacerMOB0 {
    height: 0vh;
  }
  .spacerMOB10 {
    height: 10vh;
  }
  .spacerMOB20 {
    height: 20vh;
  }
  .spacerMOB40 {
    height: 40vh;
  }
  .spacerMOB60 {
    height: 60vh;
  }
  .spacerMOB80 {
    height: 80vh;
  }
}